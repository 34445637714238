import { useEffect, useState } from 'react';
import { useTheme, useMediaQuery, Grid, Typography } from '@mui/material';

import bgBlur from './Assets/Backgrounds/bg-blur.png';
import iconSacola from './Assets/Icons/icon-bolsa.svg';
import iconAmpulheta from './Assets/Icons/ampulheta-icon.svg';

import './Custom.css';
import Contador from './Contador';
import DefaultButton from '../Buttons/DefaultButton';
import { Link } from 'react-router-dom';
import { WaitListCard } from '../Utils/Forms/NotifyMeCard';

const LotesCards = ({ turma, currentLotTurma, nextLote, matchesTablet, currentDate, targetDate, city }) => {
	const [waitListVisibility, setWaitListVisibility] = useState(false);

	const handleTextColor = (element) => {
		const endDateFormatted = new Date(element?.data_limite);

		if (element.id === currentLotTurma[0]?.id) {
			return '#FCFCFC';
		} else if (currentDate > endDateFormatted) {
			return '#656565';
		} else {
			return '#B3B3B3';
		}
	};

	const releaseDate = new Date('2024-10-08T16:00:00-03:00');
	const abriuOprimeiroLote = currentDate > releaseDate;

	const handleCloseWaitList = () => {
		setWaitListVisibility(false);
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility(true);
	};

	return (
		<div
			style={{
				display: 'flex',
				backgroundColor: '#040404',
				height: '100%',
				padding: matchesTablet ? '1.25rem' : '2rem',
				paddingTop: '48px',
				paddingBottom: '48px',
				borderRadius: '18px',
				maxWidth: matchesTablet ? '100%' : 'auto',
				flex: 1,
			}}
		>
			<Grid
				item
				container
				rowGap={2}
			>
				<p
					className="custom-class"
					style={{ fontWeight: '600', fontSize: matchesTablet ? 28 : 30 }}
				>
					{/* REMOVER ESSE FIXO DEPOIS */}
					{turma?.nome?.includes('ONLINE') ? 'Exclusivamente Online' : `Presencial em ${city} + Online`}
				</p>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						width: '100%',
					}}
				>
					{turma?.lotes?.map((lote, index) => {
						const highlight = lote?.id === currentLotTurma[0]?.id;
						const lotLimitDate = new Date(lote?.data_limite);
						// console.log(currentDate);
						// console.log(lote?.data_limite);

						return (
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '1rem',
									width: '100%',
									alignSelf: lote?.ativo && 'center',
								}}
								key={index}
							>
								<Grid
									container
									justifyContent={'center'}
									style={
										highlight && abriuOprimeiroLote
											? {
													backgroundColor: '#303030',
													padding: matchesTablet ? '10px 14px' : '10px 18px',
													borderRadius: '8px',
													width: '100%',
											  }
											: {
													padding: '10px 18px',
													borderBottom: '1px solid #303030',
											  }
									}
								>
									<Grid
										item
										container
										alignItems={'center'}
									>
										<Grid
											item
											direction={'column'}
											container
											justifyContent={'center'}
											style={{ flex: 1, paddingRight: matchesTablet && '1.5rem' }}
										>
											<p
												style={{
													color: highlight ? '#FFFFFF' : '',
													fontWeight: highlight ? '800' : '600',
													fontSize: highlight ? '24px' : '16px',
												}}
											>
												{lote?.nome}
												{/* {index === 1 && '- Abertura 09/10'} */}
											</p>
											<div
												style={{
													marginTop: (index === 0 || index === 1) && 4,
												}}
											>
												{index === 0 && (
													<p
														style={{
															color: '#B3B3B3',
															fontSize: matchesTablet ? 9 : 10,
														}}
													>
														Exclusivo para alunos Trilha R1, Sprint Final 2024 ou Lista VIP
													</p>
												)}
												{index === 1 && (
													<p
														style={{
															color: '#B3B3B3',
															fontSize: matchesTablet ? 9 : 10,
														}}
													>
														15% de desconto para Alunos Trilha ou Sprint
													</p>
												)}
											</div>
										</Grid>
										{/* MUDAR ESSA REGRA AQUI PRA MOSTRAR O VALOR QUANDO FOR NEXT LOT E TIVER PASSADO DA DATA DE LANÇAMENTO */}
										{nextLote ? (
											<Grid
												item
												style={{
													backgroundColor: '#1D1D1D',
													borderRadius: 4,
													padding: '0.25rem 0.5rem',
													height: 'fit-content',
												}}
											>
												<Typography
													color={abriuOprimeiroLote ? handleTextColor(lote) : '#B3B3B3'}
													fontSize={'16px'}
													fontWeight={700}
													fontFamily={'Manrope'}
												>
													{!abriuOprimeiroLote && index === 0 ? '08/10 às 17h' : 'EM BREVE'}
												</Typography>
											</Grid>
										) : (
											<Grid
												item
												container
												alignItems={highlight ? 'flex-start' : 'center'}
												width={'fit-content'}
											>
												<Typography
													color={handleTextColor(lote)}
													fontSize={highlight ? '24px' : '16px'}
													fontWeight={700}
													fontFamily={'Manrope'}
												>
													{currentDate > lotLimitDate ? (
														<div
															style={{
																background: '#30303015',
																padding: '8px 4px',
																borderRadius: '4px',
															}}
														>
															<p>ESGOTADO</p>
														</div>
													) : (
														`R$ ${lote?.valor?.toLocaleString('pt-BR', {
															minimumFractionDigits: 2,
															maximumFractionDigits: 2,
														})}`
													)}
												</Typography>
											</Grid>
										)}
										{lote?.id === currentLotTurma[0]?.id && nextLote && abriuOprimeiroLote ? (
											<Contador targetDate={targetDate} />
										) : (
											lote?.ativo && (
												<div
													style={{
														display: 'flex',
														flexDirection: 'column',
														justifyContent: 'center',
														alignItems: 'center',
														width: '100%',
														marginTop: '1rem',
														color: 'white',
														gap: '0.3rem',
													}}
												>
													<p style={{ fontWeight: '600', fontSize: '11px' }}>
														{' '}
														{/* <span style={{ fontWeight: '800', fontSize: '14px' }}>
                                                                    {lote?.quantidade - qtdInscritos}
                                                                </span>{' '} */}
														vagas restantes
													</p>
													<div
														style={{
															width: '100%',
															height: '10px',
															backgroundColor: '#656565',
															borderRadius: '18px',
															position: 'relative',
														}}
													>
														<div
															style={{
																// width: (lote?.quantidade - qtdInscritos) / 100,
																// width: '40%',
																position: 'absolute',
																height: '10px',
																background:
																	'linear-gradient(90deg, #FF5F45 0.82%, #FF357D 100.05%)',
																borderRadius: '18px',
															}}
														/>
													</div>
												</div>
											)
										)}
									</Grid>
								</Grid>
							</div>
						);
					})}
				</div>
				{/* lote detalhes */}
				<Grid
					item
					xs={12}
					container
				>
					<Grid
						item
						container
						width={'fit-content'}
						height={'fit-content'}
						columnGap={1}
						style={{
							border: '1px solid #959595',
							padding: '0.25rem 1rem',
							borderRadius: 8,
							marginBottom: '0.5rem',
						}}
					>
						{!abriuOprimeiroLote && (
							<img
								src={iconAmpulheta}
								alt=""
							/>
						)}
						{currentLotTurma && (
							<Typography
								color={'#FCFCFC'}
								fontFamily={'Manrope'}
								fontSize={matchesTablet ? 18 : 24}
								fontWeight={600}
							>
								{`${abriuOprimeiroLote ? currentLotTurma[0]?.nome : 'LISTA VIP'}`}
							</Typography>
						)}
						{abriuOprimeiroLote && nextLote && (
							<>
								<img
									src={iconAmpulheta}
									alt=""
								/>
								<Typography
									color={'#FCFCFC'}
									fontFamily={'Manrope'}
									fontSize={matchesTablet ? 18 : 24}
									fontWeight={600}
								>
									Em breve
								</Typography>
							</>
						)}
					</Grid>
					<Grid
						item
						container
						direction={'column'}
					>
						{abriuOprimeiroLote ? (
							<Grid
								item
								container
								alignItems={'flex-end'}
								columnGap={1}
							>
								<Typography
									fontSize={matchesTablet ? 35 : 48}
									fontWeight={600}
									color={'#959595'}
									fontFamily={'Manrope'}
									style={{ lineHeight: 'unset' }}
								>
									R${' '}
									{currentLotTurma &&
										currentLotTurma[0]?.valor?.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
								</Typography>
								<Typography
									fontSize={24}
									fontWeight={400}
									color={'#B3B3B3'}
									fontFamily={'Manrope'}
								>
									à vista
								</Typography>
							</Grid>
						) : (
							<Grid
								item
								container
							>
								<Typography
									fontSize={18}
									fontWeight={500}
									color={'#959595'}
									fontFamily={'Manrope'}
									style={{ lineHeight: 'unset' }}
								>
									Inscreva-se na Lista VIP abaixo para ter a oportunidade exclusiva de garantir uma
									vaga já no
									<strong style={{ fontWeight: 700, color: '#F1F1F1' }}> primeiro lote</strong>.
								</Typography>
							</Grid>
						)}
						{turma && abriuOprimeiroLote && (
							<Grid
								item
								container
								direction={'column'}
								style={{ marginTop: '1.5rem' }}
							>
								<Typography
									fontSize={16}
									fontWeight={400}
									color={'#B3B3B3'}
									fontFamily={'Manrope'}
								>
									<span style={{ fontWeight: 700 }}>{turma && turma?.max_parcelas}x</span> sem juros
									de
								</Typography>
								<Typography
									fontSize={24}
									fontWeight={700}
									color={'#B3B3B3'}
									fontFamily={'Manrope'}
									style={{ lineHeight: 'unset' }}
								>
									R${' '}
									{currentLotTurma &&
										(currentLotTurma[0]?.valor / turma?.max_parcelas).toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})}
									{/* {turma?.parcelas &&
										turma?.parcelas[turma?.max_parcelas - 1]?.valor?.toLocaleString('pt-BR', {
											minimumFractionDigits: 2,
											maximumFractionDigits: 2,
										})} */}
								</Typography>
							</Grid>
						)}
						{!abriuOprimeiroLote && (
							<Grid
								item
								container
								direction={'column'}
								justifyContent={'center'}
								alignItems={'center'}
								style={{
									backgroundColor: '#1D1D1D',
									borderRadius: 8,
									marginTop: '52px',
									marginBottom: '32px',
									padding: '1rem',
								}}
							>
								<Typography
									fontSize={matchesTablet ? 20 : 32}
									fontWeight={700}
									fontFamily={'Manrope'}
									color={'#FFFFFF'}
									style={{ lineHeight: 'unset' }}
								>
									Abertura do 1º lote:
								</Typography>
								<Typography
									fontSize={matchesTablet ? 16 : 20}
									fontWeight={400}
									fontFamily={'Manrope'}
									color={'#F5F5F5'}
								>
									<strong>08/10 </strong>(Terça-feira) às <strong>17:00H</strong>
								</Typography>
							</Grid>
						)}
					</Grid>
					{abriuOprimeiroLote ? (
						<DefaultButton
							variant="outlined"
							color="secondary"
							disabled={nextLote}
							className={`btn poppins`}
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								backgroundColor: nextLote ? '#959595' : '#00AD58',
								gap: '0.5rem',
								marginTop: matchesTablet ? '1.5rem' : '2.5rem',
								padding: '10px 16px',
								borderRadius: '18px',
								cursor: 'pointer',
							}}
							component={Link}
							to={turma ? `//hardworkmedicina.com.br/inscricao/${turma.id}` : ''}
						>
							<img
								src={nextLote ? iconAmpulheta : iconSacola}
								alt=""
							/>
							<p style={{ fontWeight: '700', fontSize: '20px', color: 'white' }}>
								{' '}
								{nextLote ? 'Em breve' : 'Inscreva-se'}
							</p>
						</DefaultButton>
					) : (
						<DefaultButton
							variant="outlined"
							color="secondary"
							className={`btn poppins`}
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'center',
								backgroundColor: '#00AD58',
								gap: '0.5rem',
								padding: '10px 16px',
								borderRadius: '18px',
								cursor: 'pointer',
								border: '0px',
							}}
							onClick={() => handleOpenWaitList()}
						>
							<p
								style={{
									display: 'flex',
									flexDirection: 'row',
									fontWeight: '700',
									fontSize: '20px',
									color: 'white',
								}}
							>
								<img
									src={iconAmpulheta}
									alt=""
									style={{ marginRight: 10 }}
								/>
								Entrar na Lista VIP ENARE
							</p>
						</DefaultButton>
					)}
					<WaitListCard
						group={turma?.nome}
						waitListVisibility={waitListVisibility}
						handleCloseWaitList={handleCloseWaitList}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default function CardDeCompras({ currentLotTurma1, currentLotTurma2, turma1, turma2, city }) {
	const theme = useTheme();

	const [lotActiveTurma1, setLotActiveTurma1] = useState();
	const [lotActiveTurma2, setLotActiveTurma2] = useState();
	const [nextLoteTurma1, setNextLoteTurma1] = useState();
	const [nextLoteTurma2, setNextLoteTurma2] = useState();
	const [targetDateTurma1, setTargetDateTurma1] = useState();
	const [targetDateTurma2, setTargetDateTurma2] = useState();
	const [descriptionText, setDescriptionText] = useState('');

	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesXl = useMediaQuery(theme.breakpoints.down('xl'));

	const currentDate = new Date();

	useEffect(() => {
		const activeLot = turma1?.lotes?.find((lot, index) => {
			return turma1?.id_lote_vigente === currentLotTurma1[0]?.id;
		});

		if (activeLot) {
			setLotActiveTurma1(activeLot);
			setDescriptionText('');
			setTargetDateTurma1(new Date(activeLot?.data_limite));
		} else {
			const nextLot = turma1?.lotes?.find((lot) => {
				const startDate = new Date(lot?.data_inicio);

				return currentDate < startDate;
			});
			setNextLoteTurma1(nextLot);
			setDescriptionText('Em breve!');
			setTargetDateTurma1(new Date(nextLot?.data_inicio));
		}
	}, [currentLotTurma1, turma1]);

	useEffect(() => {
		const activeLot = turma2?.lotes?.find((lot, index) => {
			return turma2?.id_lote_vigente === currentLotTurma2[0]?.id;
		});

		if (activeLot) {
			setLotActiveTurma2(activeLot);
			setDescriptionText('');
			setTargetDateTurma2(new Date(activeLot?.data_limite));
		} else {
			const nextLot = turma2?.lotes?.find((lot) => {
				const startDate = new Date(lot?.data_inicio);

				return currentDate < startDate;
			});
			setNextLoteTurma2(nextLot);
			setDescriptionText('Em breve!');
			setTargetDateTurma2(new Date(nextLot?.data_inicio));
		}
	}, [currentLotTurma2, turma2]);

	return (
		<div
			style={{
				backgroundColor: '#1D1D1D',
				backgroundImage: `url(${bgBlur})`,
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				height: 'fit-content',
				width: '100%',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				// alignItems: 'center',
				padding: matchesTablet ? '4rem 0.5rem' : '5rem',
			}}
		>
			<Grid
				item
				container
				className="section"
				style={{ margin: '0 auto' }}
				rowGap={3}
			>
				<Grid
					item
					container
					direction={matchesTablet ? 'column' : 'row'}
					rowGap={2}
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						fontFamily: 'Manrope',
						width: matchesTablet ? '95%' : '100%',
						alignSelf: 'center',
						alignItems: matchesTablet ? 'flex-start' : 'center',
					}}
				>
					<p style={{ fontWeight: 'bold', color: 'white', fontSize: matchesTablet ? 26 : 32 }}>
						Qual modelo de Live das Lives é o ideal para ti?
					</p>
					{/* <div style={{ backgroundColor: '#656565', padding: '5px 10px', borderRadius: '18px' }}>
						<p style={{ fontWeight: '600', color: 'white', fontSize: 12 }}>
							15% de desconto para ex-alunos*
						</p>
					</div> */}
				</Grid>
				<Grid
					container
					direction={'row'}
					justifyContent={turma2 ? 'space-between' : 'center'}
					// columnGap={3}
					rowGap={3}
				>
					{/* card da esquerda */}
					{turma2 && (
						<Grid
							item
							xs={12}
							lg={5.75}
						>
							<LotesCards
								turma={turma2}
								currentLotTurma={currentLotTurma2}
								nextLote={nextLoteTurma2}
								matchesTablet={matchesTablet}
								currentDate={currentDate}
								targetDate={targetDateTurma2}
								city={city}
							/>
						</Grid>
					)}
					<Grid
						item
						xs={12}
						lg={turma2 ? 5.75 : 6}
					>
						{/* card da direita */}
						<LotesCards
							turma={turma1}
							currentLotTurma={currentLotTurma1}
							nextLote={nextLoteTurma1}
							matchesTablet={matchesTablet}
							currentDate={currentDate}
							targetDate={targetDateTurma1}
							city={city}
						/>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}
