import React, { useEffect, useRef } from 'react';
import { Container, Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Header } from '../../../../Components/Header';
import StudentsFeedbackSection from '../../../../Components/Sections/StudentsFeedback';
import Footer from '../../../../Components/Footer';
import { Helmet } from 'react-helmet';
import { Splide, SplideSlide } from '@splidejs/react-splide';

// import BgWave2 from '../../../../Assets/Img/bgWave2.png';
import NewBgSprintFinal from '../../../../Assets/SprintFinal/bg-new-sprint-final.png';
import FlipCard from '../Arena/components/FlipCard';
import ProductCard from './components/ProductCard';
import DefaultButton from '../../../../Components/Buttons/DefaultButton';

import pedroMota from './assets/bg-pedro-mota.png';
import marianaGarcia from './assets/bg-mariana-garcia.png';
import gabrielaGrasso from './assets/bg-gabriela-grasso.png';
import guilhermeDantas from './assets/bg-guilherme-dantas.png';
import cristianoSarks from './assets/bg-cristiano-sarks.png';
import jeanCarlosBueno from './assets/bg-jean-carlos-bueno.png';

import BoxTitle from './components/BoxTitle';
import PaymentContainerFlex from '../componentes/PaymentContainerFlex';
import Api from '../../../../Services/Api';

const sprintBenefits = [
	{
		id: 1,
		img: require('./assets/icon-sf-benefits-1.svg').default,
		title: ['Provas dos últimos anos comentadas'],
	},
	{
		id: 2,
		img: require('./assets/icon-dot-white-residencia.svg').default,
		title: ['Cerca de 100-160 blocos de revisões por prova baseados em análise minuciosa da banca'],
	},
	{
		id: 3,
		img: require('./assets/icon-teacher-orientation-white-residencia.svg').default,
		title: ['Vídeos dos professores Hardwork com mensagens mais relevantes de cada tema revisado para cada prova'],
	},
	{
		id: 4,
		img: require('./assets/icon-cyclic-revisions-white-residencia.svg').default,
		title: [
			'Revisão ativa com uso de trívias criadas pelos professores Hardwork, e acesso às mensagens mais importantes de cada banca',
		],
	},
	{
		id: 5,
		img: require('./assets/icon-brain-engine-white-residencia.svg').default,
		title: ['Algoritmo personalizado de revisões cíclicas, garantindo aprendizado até a data da prova'],
	},
	{
		id: 6,
		img: require('./assets/icon-sf-benefits-6.svg').default,
		title: [
			'Treinamento final com mescla de questões da banca e outras similares identificadas pela Inteligência Hardwork',
		],
	},
	{
		id: 7,
		img: require('./assets/icon-mock-test-residencia.svg').default,
		title: ['Acesso ao banco de questões Hardwork'],
	},
	{
		id: 8,
		img: require('./assets/icon-direct-contact-residencia.svg').default,
		title: ['Acesso direto ao professor pelo whatsapp pessoal para dúvidas de conteúdo'],
	},
	{
		id: 9,
		img: require('./assets/icon-questions-support.svg').default,
		title: ['Suporte para recursos de questões da banca'],
	},
	{
		id: 10,
		img: require('./assets/icon-study-patterns.svg').default,
		title: ['Treinamento de padrões de imagem com o Hardwork Multimídia'],
	},
];

export default function SprintFinal2023() {
	const classes = useStyles();
	const theme = useTheme();
	const subscribe_card = useRef(null);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));
	const matchesDownLg = useMediaQuery(theme.breakpoints.down('lg'));
	const matchesDownXl = useMediaQuery(theme.breakpoints.down('xl'));

	const executeScroll = () =>
		subscribe_card.current.scrollIntoView({ behavior: 'smooth', block: matchesMobile ? 'start' : 'center' });

	const students = [
		{
			nome: 'Pedro Mota, aprovado em',
			subtitle: 'Neurologia na USP, UNIFESP, USP-RP, ENARE e UNICAMP',
			text: 'O Hardwork foi essencial para a minha preparação. As questões que eu fazia eram as que o curso sugeria e foquei no Sprint para os meus estudos. Isso fez toda a diferença.',
			img: pedroMota,
		},
		{
			nome: 'Mariana Garcia, aprovada em',
			subtitle: 'Pediatria na USP, UNIFESP e FMABC',
			text: 'Estudei estatísticas de provas desde o 3º ano e acompanhei todas as lives gratuitas do Hardwork. Usei o Sprint para questões específicas e, gostei de não ter um cronograma rígido e poder adaptar meus estudos. Precisava de uma revisão rápida e o Hardwork mudou tudo para mim.',
			img: marianaGarcia,
		},
		{
			nome: 'Gabriela Grasso, aprovada em',
			subtitle: 'Medicina de Emergência na USP, UNIFESP, UNICAMP e EINSTEIN',
			text: 'Fiz Hardwork antes e, ao prestar prova novamente, voltei porque vi a diferença no meu estudo, conhecimento e domínio das provas.',
			img: gabrielaGrasso,
		},
		{
			nome: 'Guilherme Dantas, aprovado em',
			subtitle: 'Clínica Médica na UNIFESP, USP-RP, ENARE e UNICAMP',
			text: 'O Sprint Final do Hardwork foi a cereja do bolo na reta final da preparação para as provas de residência médica. Revisões direcionadas com mensagens rápidas me fizeram guardar as abordagens de cada banca, deixando na memória os pontos-chave antes das provas. A praticidade e um estudo orientado valem ouro nesses momentos de tensão!',
			img: guilhermeDantas,
		},
		{
			nome: 'Cristiano Sarks, aprovado em',
			subtitle: 'Clínica Médica no ENARE, PSU-GO, SES-GO e SES-DF',
			text: 'O Hardwork mudou minha vida e meu jeito de pensar. A plataforma planeja revisões e foca no essencial para as provas. Os Sprints e as questões do banco foi um divisor de águas. A abordagem do Hardwork trouxe tranquilidade e organização, transformando meu método de estudo.',
			img: cristianoSarks,
		},
		{
			nome: 'Jean Carlos Bueno, aprovado em',
			subtitle: 'Medicina de Emergência na USP, ENARE, UNICAMP e SUS-SP',
			text: 'Me identifiquei com a filosofia do Hardwork. O estudo direcionado de questões mudou o jogo para mim. As aulas são interativas e te obrigam a seguir uma linha de raciocínio. O direcionamento do Hardwork fez uma diferença absurda na minha preparação.',
			img: jeanCarlosBueno,
		},
		// { img: require('./assets/bg-pedro-mota.png') },
		// { img: require('./assets/bg-mariana-garcia.png') },
		// { img: require('./assets/bg-gabriela-grasso.png') },
		// { img: require('./assets/bg-guilherme-dantas.png') },
		// { img: require('./assets/bg-cristiano-sarks.png') },
		// { img: require('./assets/bg-jean-carlos-bueno.png') },
	];

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Sprint Final Hardwork 2024</title>
				<meta
					name="description"
					content="A melhor forma de aprender é com estudo ativo
                    e revisões personalizadas!"
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignContent={'center'}
				// justifyContent={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				<img
					src={
						matchesMobile
							? require('./assets/bg-new-sf-mobile.png')
							: require('../../../../Assets/SprintFinal/bg-banner-sf.png')
					}
					alt=""
				/>
				<Grid
					item
					xs={12}
					container
					direction={'column'}
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
				>
					<img
						src={
							require(matchesMobile
								? '../../../LandingPages/Sale/SprintFinal/assets/logo-sf-residencia-2024.svg'
								: '../../../LandingPages/Sale/SprintFinal/assets/logo-sf-residencia-2024.svg').default
						}
						alt=""
						className={classes.sfBackground}
					/>
					<Grid
						item
						style={{
							width: 'fit-content',

							padding: '0rem',
							margin: 0,
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<Typography
							align="center"
							color="secondary"
							className={`${classes.text}`}
							fontFamily={'Poppins'}
							fontWeight={400}
							fontSize={20}
							marginTop={matchesMobile ? 0 : 2}
						>
							A revisão final que deixa qualquer prova de residência nas tuas mãos.
						</Typography>
						<Typography
							align="center"
							color="secondary"
							className={`${classes.text}`}
							fontFamily={'Poppins'}
							marginBottom={matchesMobile ? 2 : 0}
							fontWeight={700}
							fontSize={20}
						>
							CADA PROVA TEM O SEU SPRINT!
						</Typography>

						<DefaultButton
							variant="outlined"
							color={'primary'}
							type="revalida"
							className={`${classes.subscribeBtn} white-btn-secondary residencia poppins`}
							onClick={() => executeScroll()}
						>
							<img
								src={require('../../../../Assets/Svg/Icons/bagIcon.svg').default}
								alt=""
								className={classes.imgIconBag}
							/>
							&nbsp;&nbsp;Inscrever-se
						</DefaultButton>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				<Grid
					container
					alignContent="center"
					justifyContent="center"
					className="section"
					style={matchesMobile ? { marginTop: 0 } : { paddingBottom: '1rem 0 3rem' }}
				>
					<Grid
						item
						xs={12}
						container
						className={classes.container}
						gap={matchesTablet ? 1 : 2}
					>
						<Grid
							item
							xs={12}
							container
							style={matchesMobile ? null : { padding: '2em 0' }}
						>
							<Grid
								item
								container
								style={matchesDownLg ? { order: 1 } : { paddingRight: '2rem' }}
							>
								{!matchesTablet && (
									<img
										src={require('./assets/text-focus.svg').default}
										alt=""
										className={classes.focusImg}
									/>
								)}
								<Grid
									item
									xs={12}
									md={3}
									container
									justifyContent={'flex-end'}
									alignContent={'center'}
								>
									<img
										src={
											matchesTablet
												? require('./assets/mobile-fabricio-focus.png')
												: require('./assets/fabricio-from-the-left.png')
										}
										alt=""
									/>
								</Grid>
								<Grid
									item
									xs={12}
									md={9}
									container
									alignContent={'center'}
									gap={1}
									style={{
										backgroundImage: `url(${require('./assets/bg-glitch-1.png')})`,
										paddingLeft: matchesTablet ? null : '1rem',
									}}
								>
									<Grid
										item
										container
										alignContent="center"
										className="residencia"
										style={{
											height: 'fit-content',
										}}
									>
										<Typography
											align={'left'}
											color="secondary"
											className={`${classes.subtitle} gradient-text roboto-condensed`}
										>
											Cada prova tem o seu sprint!
										</Typography>
									</Grid>
									<Grid
										item
										container
										alignContent="flex-start"
										style={{
											height: 'fit-content',
										}}
									>
										{matchesMobile ? (
											<Typography
												align="left"
												color="secondary"
												className={`${classes.text} poppins`}
											>
												A inteligência Hardwork te faz concentrar os esforços no que é
												importante para cada instituição. Não faz qualquer sentido se preparar
												da mesma forma para provas distintas...
												<br />
												<br />
												Fazemos isso explorando os últimos 8-9 anos de cada concurso nos mínimos
												detalhes: temas principais, alternativas erradas, questões anuladas...
												<br />
												<br />
												Enfim, o Sprint retrata a mente da banca!
											</Typography>
										) : (
											<Typography
												align={'left'}
												color="secondary"
												className={`${classes.text} poppins`}
											>
												{matchesDownLg ? (
													<>
														A inteligência Hardwork te faz concentrar os esforços no que é
														importante para cada instituição.
														<br />
														Não faz qualquer sentido se
														<br />
														preparar da mesma forma para provas distintas...
														<br />
														<br />
														Fazemos isso explorando os últimos 8-9 anos de cada
														<br />
														concurso nos mínimos detalhes:
														<br />
														temas principais, alternativas erradas, questões anuladas...
														<br />
														<br />
														Enfim, o Sprint retrata a mente da banca!
													</>
												) : (
													<>
														A inteligência Hardwork te faz concentrar os esforços no que é
														importante para cada instituição.
														<br />
														Não faz qualquer sentido se preparar da mesma forma para provas
														distintas...
														<br />
														<br />
														Fazemos isso explorando os últimos 8-9 anos de cada concurso nos
														mínimos detalhes:
														<br />
														temas principais, alternativas erradas, questões anuladas...
														<br />
														<br />
														Enfim, o Sprint retrata a mente da banca!
													</>
												)}
											</Typography>
										)}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
							alignContent={'center'}
							style={{ padding: '2em 0', marginBottom: matchesTablet ? 0 : '2rem' }}
						>
							<Grid
								item
								container
							>
								{!matchesTablet && (
									<img
										src={require('./assets/text-stragety.svg').default}
										alt=""
										className={classes.strategyImg}
									/>
								)}
								<Grid
									item
									container
									justifyContent={matchesMobile ? 'center' : 'flex-start'}
									alignContent={'center'}
								>
									<Grid
										item
										xs={12}
										md={9}
										container
										justifyContent={'flex-end'}
										alignContent={'center'}
										style={{
											paddingRight: matchesTablet ? null : '1rem',
											order: matchesTablet ? 2 : 1,
											backgroundImage: `url(${require('./assets/bg-glitch-2.png')})`,
										}}
									>
										<Grid
											item
											className="residencia"
											style={{ height: 'fit-content' }}
										>
											<Typography
												align={matchesTablet ? 'left' : 'right'}
												color="secondary"
												className={`${classes.subtitle} gradient-text roboto-condensed`}
											>
												A revisão precisa ser intensa e personalizada
											</Typography>
										</Grid>
										<Grid
											item
											style={{ height: 'fit-content' }}
										>
											<Typography
												align={matchesTablet ? 'left' : 'right'}
												color="secondary"
												className={`${classes.text} poppins`}
											>
												{matchesMobile ? (
													<>
														No Sprint a revisão é intensa e dinâmica, e sempre com o
														professor Hardwork mostrando como dominar os temas como cada
														concurso gosta.
														<br />
														<br />
														As mensagens importantes para a prova são apresentadas num ritmo
														progressivo para que a gente chegue voando no dia da prova.
														<br />
														<br />E tudo isso num algoritmo personalizado, que identifica o
														que cada um mais precisa revisar.
													</>
												) : (
													<>
														No Sprint a revisão é intensa e dinâmica, e sempre com o
														professor Hardwork
														<br />
														mostrando como dominar os temas como cada concurso gosta.
														<br />
														<br />
														As mensagens importantes para a prova são apresentadas num ritmo
														progressivo
														<br />
														para que a gente chegue voando no dia da prova.
														<br />
														<br />E tudo isso num algoritmo personalizado, que identifica o
														que cada um mais precisa revisar.
													</>
												)}
											</Typography>
										</Grid>
									</Grid>
									<Grid
										item
										xs={12}
										md={3}
										container
										justifyContent={matchesTablet ? 'flex-end' : 'flex-start'}
										alignContent={'center'}
										style={{ order: matchesTablet ? 1 : 2 }}
									>
										<img
											src={
												matchesTablet
													? require('./assets/mobile-strategy.png')
													: require('./assets/fabio-from-the-right.png')
											}
											alt=""
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							container
							justifyContent={matchesTablet ? 'flex-start' : 'flex-end'}
							alignContent="center"
							style={{ padding: '2em 0' }}
						>
							<Grid
								item
								container
							>
								{!matchesTablet && (
									<img
										src={require('./assets/text-sweat.svg').default}
										alt=""
										className={classes.sweatImg}
									/>
								)}
								<Grid
									item
									container
									style={matchesDownLg ? { order: 1 } : null}
								>
									<Grid
										item
										xs={12}
										md={3}
										container
										justifyContent={'flex-end'}
										alignContent={'center'}
									>
										<img
											src={
												matchesTablet
													? require('./assets/mobile-sweat.png')
													: require('./assets/fabricio-claudio-from-the-left.png')
											}
											alt=""
										/>
									</Grid>
									<Grid
										item
										xs={12}
										md={9}
										container
										alignContent={'center'}
										style={{
											paddingLeft: matchesTablet ? null : '1rem',
											backgroundImage: `url(${require('./assets/bg-glitch-3.png')})`,
										}}
									>
										<Grid
											item
											container
											alignContent="flex-start"
											className="residencia"
											style={{ height: 'fit-content' }}
										>
											{matchesMobile ? (
												<Typography
													align={'left'}
													color="secondary"
													className={`${classes.subtitle} gradient-text roboto-condensed`}
												>
													A melhor forma de aprender é com estudo ativo e revisões
													personalizadas
												</Typography>
											) : (
												<Typography
													align="left"
													color="secondary"
													className={`${classes.subtitle} gradient-text roboto-condensed`}
												>
													A melhor forma de aprender é com estudo ativo
													<br />e revisões personalizadas
												</Typography>
											)}
										</Grid>
										<Grid
											item
											container
											alignContent="flex-end"
											style={{ height: 'fit-content' }}
										>
											{matchesMobile ? (
												<Typography
													align="left"
													color="secondary"
													className={`${classes.text} poppins`}
												>
													Chega de nos limitarmos a assistir a aulas ou de estudar pelo mesmo
													roteiro que todo mundo. As notas de corte só aumentam!
													<br />
													<br />O Sprint usa modelo ativo de aprendizado e tem revisões
													cíclicas automáticas e personalizadas, que são preparadas pela
													plataforma de acordo com a necessidade de cada um de nós...
													<br />
													<br />
													Cada um tem o seu próprio SPRINT!
												</Typography>
											) : (
												<Typography
													align="left"
													color="secondary"
													className={`${classes.text} poppins`}
												>
													Chega de nos limitarmos a assistir a aulas ou de estudar pelo mesmo
													roteiro que todo mundo. As notas de corte só aumentam!
													<br />
													<br />O Sprint usa modelo ativo de aprendizado e tem revisões
													cíclicas automáticas e personalizadas, que são preparadas pela
													plataforma de acordo com a necessidade de cada um de nós...
													<br />
													<br />
													Cada um tem o seu próprio SPRINT!
												</Typography>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid container>
				<Grid
					container
					className={classes.backgroundWaves}
				>
					<Container
						fixed
						maxWidth="xl"
						disableGutters={true}
					>
						<Grid
							container
							alignContent="center"
							className="section"
						>
							<Grid
								item
								xs={12}
								container
								style={{ marginBottom: 16 }}
							>
								<Typography
									className={`${classes.subtitle} roboto-condensed`}
									color="secondary"
								>
									O que você terá no Sprint
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								container
								justifyContent={'center'}
								style={{ marginTop: matchesMobile ? 0 : '2rem' }}
							>
								{sprintBenefits.map((item, index) => {
									return (
										<Grid
											key={item.id}
											item
											xs={6}
											md={/*activeButtonId === 1 ? 4 : */ 3}
											container
											justifyContent={'center'}
											className={`${classes.gridFlipCard} revalida`}
										>
											<FlipCard
												key={item.id}
												image={item.img}
												title={item.title}
												description={item.description}
												color="secondary"
												mobile={matchesMobile}
												size={/*activeButtonId === 1 ? 4 : */ 3}
												classes={classes}
												noFlip
											/>
										</Grid>
									);
								})}
								<Grid
									item
									xs={12}
									container
									justifyContent={'center'}
								>
									<Divider
										variant="middle"
										className={classes.divider}
									/>
								</Grid>
							</Grid>
							<Grid
								container
								justifyContent={matchesTablet ? 'center' : 'space-evenly'}
								alignContent={'center'}
								flexWrap={matchesTablet ? 'wrap' : 'nowrap'}
								gap={2}
								style={{
									margin: matchesMobile ? '1rem auto 2rem' : '3rem auto 2rem',
									padding: matchesTablet ? 0 : '0 2rem',
								}}
							>
								<ProductCard
									imgUrl={require('./assets/logo-ldl-residencia.svg').default}
									description={'Prioridade na inscrição da Live das Lives específica da instituição*'}
									classes={classes}
									color="#ffffffcc"
								/>
								{/* <ProductCard
									imgUrl={require('./assets/logo-multimidia-residencia.svg').default}
									description={'Ganhe acesso ao treinamento multimídia 2023 do HWM'}
									classes={classes}
									color="#ffffffcc"
								/> */}
							</Grid>
						</Grid>
					</Container>
				</Grid>
			</Grid>
			<Grid
				container
				className="section"
				style={{ padding: matchesTablet ? 'unset' : matchesDownXl ? '0 2rem' : 'unset' }}
				ref={subscribe_card}
			>
				<BoxTitle
					themeColor={'dark'}
					title={'Qual opção é a melhor para você?'}
					percent
				>
					<PaymentContainerFlex theme={'dark'} />
				</BoxTitle>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					className="section"
					rowGap={2}
					style={{ paddingBottom: matchesTablet && '2rem' }}
				>
					<Typography
						color={'secondary'}
						fontFamily="Manrope"
						style={{ fontSize: matchesTablet ? '1.5rem' : '3rem', fontWeight: 'bold' }}
					>
						+14.673 histórias Hardwork construídas
					</Typography>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
					>
						<Grid
							item
							xs={12}
							md={7.75}
							container
							alignItems={'center'}
							style={{
								backgroundColor: '#303030',
								borderRadius: '2rem',
								padding: matchesTablet ? '2rem 1rem' : '2.5rem 4rem',
							}}
						>
							<Grid
								item
								xs={12}
								container
								rowGap={3}
								style={{ height: 'fit-content' }}
							>
								<Typography
									color={'secondary'}
									fontFamily={'Manrope'}
									style={{ fontSize: matchesTablet ? '1.25rem' : '2rem', fontWeight: 'bold' }}
								>
									O que é o Sprint Final Hardwork?
								</Typography>
								<Typography
									color={'secondary'}
									fontFamily={'Manrope'}
									style={{ fontSize: matchesTablet ? '1rem' : '1.25rem' }}
								>
									O Sprint Final surgiu há alguns anos para revolucionar a preparação final para as
									provas de Residência.
									<br />
									<br />
									Mostramos o impacto do estudo direcionado por banca e personalizado para cada aluno.
									<br />
									<br />
									Desconstruímos os modelos limitados a aulas resumidas, e atualizamos o conceito de
									intensivo para provas de residência. O resultado? Milhares de histórias Hardwork
									criadas…
								</Typography>
							</Grid>
						</Grid>
						<Grid
							item
							xs={12}
							md={4}
							container
							style={{ marginTop: matchesTablet ? '2rem' : 'unset' }}
						>
							<Splide
								className={classes.splideMainContainer}
								options={{ arrows: false, autoplay: true, rewind: true }}
							>
								{students?.map((e, i) => {
									return (
										<SplideSlide key={i}>
											<Grid
												key={i}
												item
												container
												justifyContent={'center'}
												alignItems={'flex-end'}
												className={classes.gridImage}
												style={{
													backgroundImage: `url(${e?.img})`,
													backgroundSize: 'cover',
												}}
											>
												<Grid
													item
													container
												>
													<Grid
														item
														container
														style={{ height: 'fit-content' }}
													>
														<Typography
															color={'secondary'}
															fontFamily={'Manrope'}
															style={{
																fontWeight: 900,
																fontSize: matchesTablet ? '1.25rem' : '1.5rem',
															}}
														>
															{e?.nome}
														</Typography>
														<Typography
															color={'secondary'}
															fontFamily={'Manrope'}
															style={{
																fontWeight: 600,
																fontSize: matchesTablet ? '1.05rem' : '1.25rem',
															}}
														>
															{e?.subtitle}
														</Typography>
														<Typography
															color={'secondary'}
															fontFamily={'Manrope'}
															style={{
																fontSize: matchesTablet ? '0.95rem' : '1rem',
																marginTop: '1rem',
															}}
														>
															{e?.text}
														</Typography>
													</Grid>
												</Grid>
											</Grid>
										</SplideSlide>
									);
								})}
							</Splide>
						</Grid>
					</Grid>
				</Grid>
			</Container>
			<Grid
				item
				xs={12}
				container
				style={{ backgroundColor: '#303030' }}
			>
				<Grid
					container
					className="section"
					style={{ paddingBottom: matchesTablet && '2rem' }}
				>
					<Grid
						item
						xs={3}
						container
						justifyContent={'center'}
					>
						<img
							src={require('./assets/icon-gift.svg').default}
							alt=""
							style={matchesMobile ? { width: '80%' } : null}
						/>
					</Grid>
					<Grid
						item
						xs={9}
						container
						direction={'column'}
						justifyContent={'center'}
						className="residencia"
						style={matchesMobile ? { paddingLeft: '0.5rem' } : null}
					>
						<Typography
							className={`${classes.subtitleGift} gradient-text roboto-condensed`}
							color="secondary"
						>
							Vantagem para Alunos Trilha R1 2024
						</Typography>
						<Typography
							align="left"
							color="secondary"
							className={`${classes.text} poppins`}
						>
							Alunos do Extensivo R1 2024 possuem acesso gratuito e antecipado a todos os Sprints Finais.
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<StudentsFeedbackSection type="residencia" />
			{/* <Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Faq
					type="residencia"
					// title="Como podemos te ajudar?"
				/>
			</Container> */}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Container
					fixed
					maxWidth="xl"
					disableGutters={true}
				>
					{matchesTablet ? null : <Footer />}
				</Container>
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	text: {
		fontSize: '1.15rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('xl')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.8rem !important',
		},
	},
	subtitle: {
		fontSize: '2.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	backgroundWaves: {
		backgroundImage: `url(${NewBgSprintFinal})`,
		width: '100vw !important',
		backgroundSize: 'cover',
	},
	gridFlipCard: {
		margin: '15px 0 !important',
		height: 280,
		padding: '0 1rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
			height: 225,
		},
	},
	divider: {
		minHeight: 1,
		backgroundColor: '#ffffff',
		width: '100%',
		margin: '2rem 0 !important',
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
		},
	},
	logoBtn: {
		filter: 'invert(1)',
		transition: 'ease-in-out 0.2s',
		[theme.breakpoints.down('xl')]: {
			height: 30,
		},
		[theme.breakpoints.down('sm')]: {
			height: 17,
		},
	},
	techBackground: {
		backgroundImage: `url(${require('../../../../Assets/SprintFinal/bg-tech-grey.png')})`,
		backgroundSize: 'cover',
		padding: '4rem',
		[theme.breakpoints.down('sm')]: {
			padding: 'unset',
		},
	},
	subscribeBtn: {
		width: 'fit-content',
		padding: '0.5rem 5rem !important',
		alignSelf: 'center',
		fontSize: '1.35rem !important',
		fontWeight: 'bold !important',
		marginTop: '2rem !important',
		border: '2px solid transparent !important',
		'&:hover': {
			scale: 1.05,
			color: '#ffffff !important',
			'& $logoBtn': {
				filter: 'invert(0)',
			},
		},
		'&:hover $imgIconBag': {
			filter: 'invert(0)',
		},
		[theme.breakpoints.down('xl')]: {
			padding: '0.25rem 2.5rem !important',
		},
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '0.85rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			padding: '0.25rem 1.25rem !important',
			marginTop: 'unset !important',
		},
	},
	textStyle: {
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
		},
	},
	sfBackground: {
		alignSelf: 'center',
		// [theme.breakpoints.down('xl')]: {
		// 	width: '70%',
		// },
		[theme.breakpoints.down(1509)]: {
			width: '36%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '62%',
			marginBottom: 12,
		},
	},
	gridBannerImage: {
		backgroundSize: 'cover',
		height: '74vh',
		[theme.breakpoints.down('xl')]: {
			height: '61vh',
		},
		[theme.breakpoints.down('lg')]: {
			height: '49vh',
		},
		[theme.breakpoints.down('md')]: {
			height: '36vh',
		},
		[theme.breakpoints.down('sm')]: {
			height: '34vh',
			marginTop: '65px',
		},
		[theme.breakpoints.down(376)]: {
			height: '42vh',
		},
		[theme.breakpoints.down(361)]: {
			height: '36vh',
		},
	},
	focusImg: {
		position: 'absolute !important',
		top: -20,
		zIndex: 1,
		left: 215,
		width: '35%',
		// [theme.breakpoints.down('xl')]: {
		// 	bottom: -35,
		// },
		[theme.breakpoints.down('lg')]: {
			left: 130,
			top: -60,
			width: '45%',
		},
		[theme.breakpoints.down('md')]: {
			left: 60,
			// bottom: -45,
		},
	},
	strategyImg: {
		position: 'absolute',
		top: -30,
		zIndex: 1,
		right: 258,
		width: '35%',
		// [theme.breakpoints.down('xl')]: {
		// 	bottom: -35,
		// },
		[theme.breakpoints.down('lg')]: {
			right: 130,
			top: -60,
			width: '45%',
			[theme.breakpoints.down('md')]: {
				right: 130,
				// bottom: -55,
			},
		},
	},
	sweatImg: {
		position: 'absolute',
		top: -45,
		zIndex: 1,
		left: 315,
		width: '35%',
		// [theme.breakpoints.down('xl')]: {
		// 	bottom: -35,
		// },
		[theme.breakpoints.down('lg')]: {
			left: 130,
			top: -50,
			width: '45%',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
		height: '100%',
	},
	subtitleGift: {
		fontSize: '3.75rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		marginBottom: '1rem !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('md')]: {
			fontSize: '1.75rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5rem !important',
			marginBottom: '0.5rem !important',
		},
	},
	splideMainContainer: {
		width: '100%',
		'& .splide__arrow': {
			width: '4em',
			height: '4em',
			marginTop: '23%',
		},
		'& .splide__arrow svg': {
			height: '2em',
			width: '2em',
		},
		'& .splide__pagination': {
			display: 'flex !important',
			bottom: '0.5em !important',
		},
		'& .splide__track--draggable': {
			// margin: '.5em 0 !important',
			height: '100%',
		},
		[theme.breakpoints.down('sm')]: {
			'& .splide__pagination': {
				bottom: '0.75em !important',
			},
		},
	},
	gridImage: {
		borderRadius: 15,
		width: '100%',
		padding: '3rem 1.5rem !important',
		height: 585,
	},
	imgIconBag: {
		filter: 'invert(1)',
		transition: 'filter 0.3s ease',
	},
}));
