import { useEffect } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Divider, Grid, Paper, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { trackPromise } from 'react-promise-tracker';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

// Components
import { Header } from '../../Components/Header';
import { makeStyles } from '@mui/styles';
import DefaultButton from '../../Components/Buttons/DefaultButton';
import Footer from '../../Components/Footer';
import Api from '../../Services/Api';
import IntlTelInput from 'react-intl-tel-input';
import Ampulheta from './Assets/icon-ampulheta.svg';

const CardLiveDasLives = ({
	id,
	logo,
	mode,
	dateDay,
	dateMonth,
	classes,
	url,
	contest,
	matchesMobile,
	opening,
	appear,
}) => {
	const [preInscricao, setPreInscricao] = useState({
		agrupamento: `LDL RESIDÊNCIA 2024.2 - ${contest}`,
		// agrupamento: contest,
		nome: '',
		email: '',
		telefone: '',
		utm_source: '',
		utm_medium: '',
		utm_campaign: '',
	});
	const [waitListVisibility, setWaitListVisibility] = useState(false);
	const [lot, setLot] = useState([]);
	const [numParticipants, setNumParticipants] = useState(0);
	const currentDate = new Date();
	const [classSold, setClassSold] = useState(false);

	useEffect(() => {
		if (id) {
			Api.get(`turmasabertas/${id}`).then((res) => {
				const currentDate = new Date();
				setNumParticipants(res[0]?.qtd_inscritos);
				const activeLot = res[0]?.lotes?.find((lot, index) => {
					return lot?.id === res[0]?.id_lote_vigente;
				});

				if (activeLot) {
					setLot(activeLot);
				} else {
					const nextLot = res[0]?.lotes?.find((lot, index) => {
						const startDate = new Date(lot.data_inicio);

						return currentDate < startDate;
					});

					if (!nextLot && opening === 'Aberto') {
						// console.log('contest', contest)
						setClassSold(true);
					} else {
						setLot(nextLot);
					}
				}
			});
		}
	}, [id]);

	const isActive = new Date(lot?.data_inicio) <= currentDate && currentDate <= new Date(lot?.data_limite);

	const handleCloseWaitList = () => {
		setWaitListVisibility(false);
	};

	const handleOpenWaitList = () => {
		setWaitListVisibility(true);
	};

	let sendPreInscricao = function (event) {
		event.preventDefault();
		const nomeCompleto = preInscricao.nome.trim();
		const nomeSobrenome = nomeCompleto.split(' ');
		if (!preInscricao.nome || nomeSobrenome.length < 2) {
			alert('Ops... Por favor informe seu nome e sobrenome');
			return;
		}
		if (!preInscricao.email || !preInscricao.email.includes('@') || !preInscricao.email.includes('.')) {
			alert('Ops... Por favor preencha seu email');
			return;
		}
		if (!preInscricao.telefone) {
			alert('Ops... Por favor preencha seu telefone');
			return;
		}

		trackPromise(
			Api.post('PreInscricao', preInscricao).then((res) => {
				if (res) {
					Swal.fire({
						title: 'Massa! ',
						text: `${'O cadastro está confirmado, avisaremos das novidades em breve.'}`,
						icon: 'success',
						confirmButtonText: 'Fechar',
						allowOutsideClick: false,
						allowEscapeKey: false,
					}).then((result) => {
						if (result.isConfirmed) {
							setTimeout(() => {
								document.location.reload();
							}, 500);
						}
					});
				}
			})
		);
	};

	const handlePhone = (valid, phoneNumber) => {
		if (valid) {
			setPreInscricao({
				...preInscricao,
				telefone: phoneNumber,
			});
		} else if (preInscricao.telefone.length) {
			setPreInscricao({
				...preInscricao,
				telefone: '',
			});
		}
	};

	function dateFormat(data) {
		if (data) {
			const dataObjeto = new Date(data);
			const dia = String(dataObjeto.getDate()).padStart(2, '0');
			const mes = String(dataObjeto.getMonth() + 1).padStart(2, '0'); // Adicionar +1 pois o mês começa em zero (janeiro é 0)
			return `${dia}/${mes}`;
		} else {
			return null;
		}
	}

	function formatTime(data) {
		const dataObj = new Date(data);
		const hours = dataObj.getHours();
		const minutes = dataObj.getMinutes();

		if (hours === 0 && minutes === 0) {
			return '00h';
		} else if (minutes === 0) {
			return `${hours}h`;
		} else {
			return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}h`;
		}
	}

	return (
		<Paper
			className={`${classes.rootCard} rounded-card `}
			elevation={4}
			style={{
				backgroundColor: url && opening !== 'Fechado' ? null : '#0000004d',
				border: url && opening !== 'Fechado' ? '1px solid #FF4A60' : '1px solid #ff4a604d',
			}}
		>
			<Grid
				style={{
					opacity: url && opening !== 'Fechado' ? null : 0.3,
				}}
				className={`${classes.borderGrid} background-central-concursos-vertical`}
			></Grid>
			<Grid
				container
				className={classes.gridBackground}
				style={{
					backgroundImage: url
						? `url(${require('../../Assets/LDL/bg-ldl-card.png')})`
						: `url(${require('../../Assets/LDL/bg-ldl-soon.png')})`,
				}}
			>
				<Grid
					item
					xs={12}
					container
					wrap="nowrap"
					alignItems={'center'}
				>
					<Grid
						item
						xs={url ? 9.5 : 12}
						container
						justifyContent={'center'}
						style={{
							borderRight:
								url && opening === 'Aberto'
									? '1px solid #FFFFFF'
									: opening === 'Fechado'
									? '1px solid #FFFFFF4d'
									: null,
							paddingRight: '2rem',
							height: 'fit-content',
						}}
					>
						<img
							src={logo}
							alt=""
							style={{
								opacity: url && opening !== 'Fechado' ? 1 : 0.3,
								width:
									(contest === 'UNIFESP' || contest === 'SES-DF' || contest === 'SANTA CASA-SP') &&
									!matchesMobile
										? '80%'
										: null,
								height: 85,
							}}
						/>
					</Grid>
					{dateDay && (
						<Grid
							item
							xs={2.5}
							container
							direction={'column'}
							alignContent={'center'}
							justifyContent={'center'}
						>
							<Typography
								align="center"
								color="secondary"
								className={`${classes.dayDateText} poppins`}
								style={{ opacity: opening === 'Fechado' ? 0.3 : 1 }}
							>
								{dateDay}
							</Typography>
							<Typography
								align="center"
								color="secondary"
								className={`${classes.monthDateText} poppins`}
								style={{ opacity: opening === 'Fechado' ? 0.3 : 1 }}
							>
								{dateMonth}
							</Typography>
						</Grid>
					)}
				</Grid>
				{contest?.includes('ENARE') && (
					<Grid
						item
						xs={12}
						container
						alignContent="center"
						// justifyContent={'space-between'}
						justifyContent={'center'}
						wrap="nowrap"
						className={classes.gridInformation}
					>
						{/* <Typography
						align={matchesMobile ? 'center' : 'left'}
						color="secondary"
						className={`${classes.modeText} poppins`}
						style={{ opacity: opening === 'Fechado' ? 0.3 : 1 }}
					>
						{mode}
					</Typography> */}
						<Typography
							align={matchesMobile ? 'center' : 'left'}
							color="secondary"
							className={`${classes.modeText} poppins`}
							style={{ opacity: opening === 'Fechado' ? 0.3 : 1 }}
						>
							PRESENCIAL EM FORTALEZA E ONLINE PARA TODO BRASIL
						</Typography>
						{/* <DefaultButton
						variant="outlined"
						color={'secondary'}
						className={`${classes.subscribeBtn} poppins ${
							opening === 'Fechado'
								? 'background-gradient-gray'
								: 'background-central-concursos-horizontal'
						}`}
						component={Link}
						to={url || null}
						onClick={() => handleOpenWaitList()}
					>
						{!url && !matchesMobile && (
							<img
								src={require('../../Assets/Svg/icon-white-bell.svg').default}
								alt=""
								style={{ width: 12 }}
							/>
						)}
						<p style={{ width: 'fit-content' }}>
							&nbsp;{url ? (isActive ? 'Garantir Vaga' : 'Ver detalhes') : `Lista de espera ${contest}`}
						</p>
					</DefaultButton> */}
					</Grid>
				)}
				{contest?.includes('ENARE') ? null : (
					<div
						style={{
							backgroundColor: 'black',
							padding: '15px',
							borderRadius: '16px',
							marginTop: '1.5rem',
							display: 'flex',
							gap: '1rem',
						}}
					>
						<img
							src={Ampulheta}
							alt=""
						/>
						<p style={{ color: 'white', fontSize: matchesMobile ? '11px' : '15px' }}>
							Inscreva-se na lista VIP da Live das Lives {contest} para ter a oportunidade de garantir a
							sua vaga já no primeiro lote.
						</p>
					</div>
				)}
				<div
					style={{
						display: 'flex',
						// justifyContent: contest?.includes('ENARE') ? 'space-between' : 'flex-end',
						justifyContent: 'flex-end',
						width: '100%',
						marginTop: '2rem',
					}}
				>
					{/* {contest?.includes('ENARE') && (
						<div
							style={{
								backgroundColor: '#313131',
								display: 'flex',
								padding: '8px 14px',
								borderRadius: '4px',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<div>
								<Typography
									color={'#fff'}
									className="poppins"
									fontWeight={500}
									style={{ fontSize: matchesMobile ? '10px' : '14px' }}
								>
									ABERTURA: 08/10 ÀS 17:00H
								</Typography>
							</div>
						</div>
					)} */}
					<DefaultButton
						variant="outlined"
						color={'secondary'}
						className={`${classes.subscribeBtn} poppins`}
						style={{ backgroundColor: '#30AC5A' }}
						component={Link}
						to={url || null}
						onClick={() => (!url ? handleOpenWaitList() : null)}
					>
						{!url && (
							<img
								src={require('../../Assets/Svg/icon-white-bell.svg').default}
								alt=""
								style={{ width: 12 }}
							/>
						)}
						{/* SEGUNDA VERIFICAÇÃO DA URL FOI PRA RESOLVER DE ÚLTIMA HORA */}
						&nbsp;{url ? (isActive ? 'Garantir Vaga' : 'Ver detalhes') : !url ? '' : 'Avise-me'}
						{!url && (
							<div>
								<div>
									{contest?.includes('ENARE') ? (
										<p
											style={{ fontWeight: '400', fontSize: matchesMobile ? '10px' : '14px' }}
											className="poppins"
										>
											LISTA VIP ENARE{' '}
										</p>
									) : (
										<p
											style={{ fontWeight: '400', fontSize: matchesMobile ? '10px' : '14px' }}
											className="poppins"
										>
											LISTA VIP {contest}{' '}
										</p>
									)}
								</div>
							</div>
						)}
					</DefaultButton>
				</div>
				{contest && (
					<form
						className="form-control"
						onSubmit={sendPreInscricao}
						action="javascript:;"
						style={
							waitListVisibility
								? {
										overflow: 'hidden',
										transition: 'opacity 1s ease, height 1s ease',
										marginTop: '0.5rem',
								  }
								: { width: '100%', visibility: 'hidden', height: 0 }
						}
					>
						<Grid
							item
							container
							justifyContent={'space-between'}
							className={classes.containerWaitList}
							rowGap={matchesMobile ? 1 : 0}
						>
							<Grid
								item
								container
								justifyContent="space-between"
							>
								<Typography
									color="primary"
									className={`${classes.textWaitList} poppins`}
								>
									Cadastre-se para entrar na Lista VIP
								</Typography>
								<CancelRoundedIcon
									onClick={() => handleCloseWaitList()}
									className={classes.cancelIcon}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
								container
							>
								<TextField
									fullWidth
									type="string"
									name="nome"
									placeholder="Seu nome completo"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											nome: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={5.75}
								container
								alignContent={'center'}
							>
								<IntlTelInput // Componente Input de telefones internacionais
									name="mobile"
									type="mobile"
									preferredCountries={['BR', 'PY', 'AR', 'CL', 'BO', 'UY', 'CU', 'PT']}
									style={{ width: '100%', border: 'unset' }}
									inputClassName={`${classes.phoneNumberForm} ${classes.form} ${classes.formHeight}`}
									nationalMode={true}
									onPhoneNumberChange={(valid, value, selectedCountryData, fullNumber) =>
										handlePhone(valid, fullNumber)
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								container
							>
								<TextField
									fullWidth
									type="email"
									name="email"
									placeholder="Seu melhor e-mail"
									required={true}
									InputProps={{
										className: `${classes.form} ${classes.formHeight}`,
									}}
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: '100%' }}
									onChange={(e) =>
										setPreInscricao({
											...preInscricao,
											email: e.target.value,
										})
									}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								md={10}
								lg={5}
								container
								className="residencia"
								style={{ padding: '4px 0px', margin: '0 auto' }}
							>
								<DefaultButton
									type="submit"
									variant="outlined"
									color="secondary"
									className={`${classes.btnWaitList} btn`}
									onClick={(e) => sendPreInscricao(e)}
								>
									<img
										src={require('../../Assets/Arena/icon-waitList.svg').default}
										alt=""
										style={{ width: 20 }}
									/>{' '}
									&nbsp; Confirmar cadastro
								</DefaultButton>
							</Grid>
						</Grid>
					</form>
				)}
			</Grid>
		</Paper>
	);
};

export default function LiveDasLives() {
	const theme = useTheme();
	const classes = useStyles();
	const [livesOptions, setLivesOptions] = useState([]);
	const [closedCards, setClosedCards] = useState([
		{
			id: 1418,
			nome: 'AMP',
			logo: require('../../Assets/LDL/logo-ldl-amp.svg').default,
			mode: 'ONLINE',
			dateDay: '02',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1425,
			nome: 'SES/PSU-GO',
			logo: require('../../Assets/LDL/logo-ldl-ses-psu-go.svg').default,
			mode: 'INFORMAÇÕES EM BREVE',
			dateDay: '09',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1411,
			nome: 'SUS-BA',
			logo: require('../../Assets/LDL/logo-ldl-sus-ba.svg').default,
			mode: 'PRESENCIAL EM SALVADOR',
			dateDay: '14',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1417,
			nome: 'AMRIGS',
			logo: require('../../Assets/LDL/logo-ldl-amrigs.svg').default,
			mode: 'ONLINE',
			dateDay: '16',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1419,
			nome: 'UNICAMP',
			logo: require('../../Assets/LDL/logo-ldl-unicamp.svg').default,
			mode: 'PRESENCIAL EM CAMPINAS',
			dateDay: '16',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1412,
			nome: 'USP-RP',
			logo: require('../../Assets/LDL/logo-ldl-usp-ribeirao.svg').default,
			mode: 'PRESENCIAL EM RIBEIRÃO PRETO',
			dateDay: '19',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1420,
			nome: 'UFRJ',
			logo: require('../../Assets/LDL/logo-ldl-ufrj.svg').default,
			mode: 'PRESENCIAL EM RJ',
			dateDay: '23',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1421,
			nome: 'FAMERP',
			logo: require('../../Assets/LDL/logo-ldl-famerp.svg').default,
			mode: 'PRESENCIAL EM SÃO JOSÉ DO RIO PRETO',
			dateDay: '25',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1413,
			nome: 'PSU-MG',
			logo: require('../../Assets/LDL/logo-ldl-psu-mg.svg').default,
			mode: 'PRESENCIAL EM BELO HORIZONTE',
			dateDay: '28',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1414,
			nome: 'USP-SP',
			logo: require('../../Assets/LDL/logo-ldl-usp-sp.svg').default,
			mode: 'PRESENCIAL EM SÃO PAULO',
			dateDay: '29',
			dateMonth: 'NOV',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1415,
			nome: 'UNIFESP',
			logo: require('../../Assets/LDL/logo-ldl-unifesp.svg').default,
			mode: 'INFORMAÇÕES EM BREVE',
			dateDay: '07',
			dateMonth: 'DEZ',
			url: '',
			opening: 'Fechado',
		},
		{
			id: 1422,
			nome: 'SUS-SP',
			logo: require('../../Assets/LDL/logo-ldl-sus-sp.svg').default,
			mode: 'PRESENCIAL EM SÃO PAULO',
			dateDay: '14',
			dateMonth: 'DEZ',
			url: '',
			opening: 'Fechado',
		},
	]);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		// O parâmetro define que serão retornadas lives de até 5 meses atrás
		Api.get(`turmasabertas/1409`).then((res) => {
			const currentDate = new Date();
			const arr = res.map((item) => {
				const contest = item?.nome.split(' - ')[1]?.replace('2024.2', '')?.replace('2024', '')?.trim();
				const mode = item.nome.includes('ENARE')
					? 'PRESENCIAL OU ONLINE'
					: item.nome.includes('PRESENCIAL')
					? 'EXCLUSIVAMENTE PRESENCIAL'
					: 'TOTALMENTE ONLINE';
				const dateDay = new Date(item.data_inicio_turma).getDate().toString().padStart(2, '0');
				const dateMonth = new Date(item.data_inicio_turma)
					.toLocaleString('pt-BR', { month: 'short' })
					.toUpperCase()
					.replace('.', '');
				const expirationDate = item.data_fim_inscricoes;
				const active = !item.encerrada;
				const url = `/live-das-lives-${
					contest === 'SANTA CASA-SP' ? 'santa-casa-sp' : contest.toLowerCase().replace('sus-sp', 'sus')
				}`;
				const opening =
					new Date(expirationDate) < currentDate && expirationDate !== ''
						? 'Fechado'
						: new Date(expirationDate) > currentDate && expirationDate !== ''
						? 'Aberto'
						: 'Em breve';

				return {
					...item,
					contest,
					mode,
					dateDay,
					dateMonth,
					expirationDate,
					active,
					url,
					opening,
					appear: '',
				};
			});

			const filteredArrayWithClosedCards = arr.filter(
				(elm) => elm.logo && elm.opening === 'Fechado' && elm.opening !== ''
			);
			const filteredArrayWithoutClosedCards = arr.filter((elm) => elm.logo && elm.opening !== 'Fechado');
			// setClosedCards([...filteredArrayWithClosedCards]);
			setLivesOptions([...filteredArrayWithoutClosedCards]);
		});
	}, []);

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Live das Lives 2024</title>
				<meta
					name="description"
					content={''}
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
				className="residencia"
			>
				{matchesMobile || matchesTablet ? null : <Header />}
			</Container>
			<Grid
				container
				alignContent={'center'}
				style={matchesMobile ? { marginTop: 80 } : null}
			>
				<video
					src={process.env.PUBLIC_URL + '/Assets/video-ldl-background-24-2.mp4'}
					autoPlay
					loop
					muted
					playsInline
					className={classes.videoBackground}
				/>
				<Grid
					item
					xs={12}
					container
					alignContent={'center'}
					justifyContent={'center'}
					className={`${classes.gridLogoContainer} residencia`}
					rowGap={1}
				>
					<img
						src={require('../../Assets/LDL/liveDasLivesResidencia.svg').default}
						alt=""
						className={classes.ldlLogo}
						style={matchesMobile ? { margin: '1rem 0', width: '72%' } : null}
					/>
					<Grid
						item
						xs={12}
						container
						alignContent={'center'}
						justifyContent={'center'}
						style={{
							padding: matchesMobile ? '1rem' : '1rem 0',
							border: '1px solid #FF005D',
							borderLeft: 'unset',
							borderRight: 'unset',
							margin: '1rem 0',
							background:
								'linear-gradient(90deg, rgba(19,19,19,0) 0%, rgba(15,15,15,1) 20%, rgba(15,15,15,1) 80%, rgba(19,19,19,0) 100%)',
						}}
					>
						<Typography
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: 'bold' }}
						>
							REVISÃO DE VÉSPERA ESPECÍFICA PARA CADA PROVA
						</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				<Grid
					container
					className="section"
					style={{ margin: matchesMobile ? '60px auto 80px' : '20px auto 80px' }}
				>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={2}
					>
						{livesOptions.map((item, index) => (
							<Grid
								item
								key={index}
								xs={12}
								md={5.8}
							>
								<CardLiveDasLives
									id={item?.id}
									// contest={item.contest}
									contest={item.nome}
									logo={item.logo}
									mode={item.mode}
									dateDay={item.dateDay}
									dateMonth={item.dateMonth}
									url={item.url}
									classes={classes}
									matchesMobile={matchesMobile}
									opening={item.opening}
									appear={true}
								/>
							</Grid>
						))}
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						alignItems={'center'}
						wrap="nowrap"
						style={{ padding: '2.5rem 0' }}
					>
						<Divider className={classes.divider} />
						<Typography
							color="secondary"
							className="poppins"
							style={{ fontSize: matchesMobile ? '1rem' : '2rem', fontWeight: '600' }}
						>
							EM BREVE
						</Typography>
						<Divider className={classes.divider} />
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={4}
					>
						{closedCards.map((item, index) => {
							return (
								<Grid
									item
									key={index}
									xs={12}
									md={5.8}
								>
									<CardLiveDasLives
										id={item?.id}
										// contest={item.contest}
										contest={item.nome}
										logo={item.logo}
										mode={item.mode}
										dateDay={item.dateDay}
										dateMonth={item.dateMonth}
										url={item.url}
										classes={classes}
										matchesMobile={matchesMobile}
										opening={item.opening}
										appear={true}
									/>
								</Grid>
							);
						})}
					</Grid>
					<Grid
						item
						xs={12}
						container
						justifyContent={'space-between'}
						rowGap={4}
					>
						{livesOptions.map((item, index) => {
							return !item.active ? (
								<Grid
									item
									xs={12}
									md={5.8}
								>
									<CardLiveDasLives
										key={index}
										contest={item.contest}
										logo={item.logo}
										mode={item.mode}
										dateDay={item.dateDay}
										dateMonth={item.dateMonth}
										classes={classes}
										matchesMobile={matchesMobile}
										opening={item.opening}
										appear={false}
									/>
								</Grid>
							) : null;
						})}
					</Grid>
				</Grid>
			</Container>
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	videoBackground: {
		objectFit: 'cover',
		height: 676,
		width: '100%',
		[theme.breakpoints.down('xl')]: {
			height: 480,
		},
		[theme.breakpoints.down('md')]: {
			height: 332,
		},
		[theme.breakpoints.down('sm')]: {
			height: 283,
		},
	},
	ldlLogo: {
		alignSelf: 'center',
		[theme.breakpoints.down(1509)]: {
			width: '36%',
		},
		[theme.breakpoints.down('sm')]: {
			alignSelf: 'center',
			width: '62%',
		},
	},
	gridLogoContainer: {
		position: 'absolute',
		height: '100%',
		[theme.breakpoints.down('sm')]: {
			top: 50,
		},
	},
	rootCard: {
		display: 'flex !important',
		padding: 0,
	},
	dayDateText: {
		fontSize: '3.85rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.85rem !important',
		},
	},
	monthDateText: {
		fontSize: '2.25rem !important',
		fontWeight: 'bold !important',
		lineHeight: '0.9 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem !important',
		},
	},
	gridBackground: {
		backgroundSize: 'cover',
		padding: '0.8rem 1rem',
		height: '100%',
		borderTopRightRadius: 20,
		borderBottomRightRadius: 20,
		[theme.breakpoints.down('sm')]: {
			padding: '1.5rem 0.5rem 1rem',
		},
	},
	gridInformation: {
		backgroundColor: '#000000bf',
		borderRadius: '10px',
		padding: '0.5rem 1rem',
		marginTop: '1rem !important',
		[theme.breakpoints.down('sm')]: {
			padding: '0.5rem',
		},
	},
	subscribeBtn: {
		width: 'fit-content !important',
		padding: '0 2rem !important',
		transition: 'ease 0.2s !important',
		alignSelf: 'center',
		border: '1px transparent !important',
		fontWeight: 'bold !important',
		fontSize: '0.95rem !important',
		display: 'flex',
		flexDirection: 'row',
		textWrap: 'nowrap',

		'&:hover': {
			width: 'fit-content!important',
			scale: 1.1,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
			width: 'fit-content!important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(361)]: {
			width: 'fit-content !important',
		},
	},
	lotGrid: {
		borderRadius: '10px',
		padding: 2,
		[theme.breakpoints.down('sm')]: {
			padding: '0.3rem',
			fontSize: '0.5rem !important',
		},
	},
	modeText: {
		alignSelf: 'center',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.7rem !important',
		},
		[theme.breakpoints.down(376)]: {
			fontSize: '0.8rem !important',
		},
		[theme.breakpoints.down(361)]: {
			fontSize: '0.75rem !important',
		},
	},
	lotText: {
		alignSelf: 'center',
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		lineHeight: 'unset !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.75rem !important',
		},
	},
	divider: {
		alignSelf: 'center',
		backgroundColor: '#ffffff',
		minHeight: 1,
		width: '41%',
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0 !important',
			width: '32%',
		},
	},
	borderGrid: {
		width: '2.5%',
		borderTopLeftRadius: 15,
		borderBottomLeftRadius: 15,
		[theme.breakpoints.down('sm')]: {
			width: '5%',
		},
	},
	containerWaitList: {
		// width: '100% !important',
		backgroundColor: '#d6cece',
		zIndex: 1,
		borderRadius: 15,
		padding: '0.5rem 1.25rem',
		boxShadow: '0px 0px 30px 0px #000000',
		border: '2px solid #000000',
		// position: 'absolute',
		// bottom: 0,
		[theme.breakpoints.down('sm')]: {
			padding: '1rem 1.25rem',
		},
	},
	textWaitList: {
		fontSize: '1rem !important',
		fontWeight: 'bold !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.95rem !important',
		},
	},
	form: {
		borderRadius: '8px !important',
		backgroundColor: '#E1E1E1 !important',
		margin: '5px 0 !important',
		'& .MuiInputBase-input': {
			padding: '0.5rem !important',
		},
	},
	formHeight: {
		maxHeight: '2.5em',
	},
	btnWaitList: {
		fontSize: '0.9rem !important',
		minHeight: 'unset !important',
		fontWeight: 'bold !important',
		backgroundColor: '#00AB55 !important',
		border: '1px solid #707070 !important',
		[theme.breakpoints.up('lg')]: {
			'&:hover': {
				// minWidth: '72% !important',
				// fontSize: '1.025rem !important',
				// borderWidth: '3px !important',
				backgroundColor: '#00543b !important',
				transition: '0.1s',
			},
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.85rem !important',
		},
	},
	cancelIcon: {
		color: '#000000',
		right: '-15px',
		top: '-5px',
		'&:hover': {
			cursor: 'pointer',
		},
		[theme.breakpoints.down('sm')]: {
			position: 'absolute',
			top: '-15px',
			right: '-18px',
		},
	},
	phoneNumberForm: {
		height: '2.5em !important',
		borderRadius: 8,
		width: '100%',
		border: 'unset',
	},
}));
