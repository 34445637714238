import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Footer from '../../../../Components/Footer';
import { Header } from '../../../../Components/Header';

import Form from '../../../../Components/Utils/Forms/PreRegistration';
import { Helmet } from 'react-helmet';

export default function TemplateSalePage20232() {
	const classes = useStyles();
	const theme = useTheme();
	const jsonMock_page_data = useState([
		{
			imagem: require('../../../../Assets/Extensivo/R1/2023-24/logo-ext-now-is-trilha-r1.svg').default,
			agrupamento: 'TRILHA RESIDÊNCIA',
			produto_color: 'residencia',
			titulo_formulario: 'Cadastre-se para saber detalhes',
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			campo_de_selecao: ['Fazer a prova esse ano', 'UFRJ', '01 01 01 00 1001 01', 'Outros'],
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar inscrição',
			url_de_redirecionamento: '/pre-inscricao/trilha-residencia',
			titulo_popup_conclusao: '',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.',
		},
		{
			imagem: require('../../../../Assets/Extensivo/R1/2023-24/logo-ext-now-is-trilha-r1-24-25.svg').default,
			agrupamento: 'TRILHA-R1 2024-2025',
			produto_color: 'residencia',
			titulo_formulario: ['Preencha o formulário para', <br />, 'realizar sua pré-inscrição!'],
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			campo_de_selecao: ['Fazer a prova esse ano', 'UFRJ', '01 01 01 00 1001 01', 'Outros'],
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar pré-inscrição',
			url_de_redirecionamento: '/pre-inscricao/trilha-residencia-2-anos',
			titulo_popup_conclusao: '',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.',
		},
		{
			imagem: require('../../../../Assets/Extensivo/R1/2023-24/logo-ext-now-is-trilha-r3-cm.png').default,
			agrupamento: 'TRILHA-R3 CLÍNICA 2024',
			produto_color: 'gradient-purple',
			titulo_formulario: ['Preencha o formulário para', <br />, 'realizar sua pré-inscrição!'],
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			campo_de_selecao: ['Fazer a prova esse ano', 'UFRJ', '01 01 01 00 1001 01', 'Outros'],
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar pré-inscrição',
			url_de_redirecionamento: '/pre-inscricao/trilha-r3-cm',
			titulo_popup_conclusao: '',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.',
		},
		{
			imagem: require('../../../../Assets/Extensivo/R1/2023-24/logo-ext-now-is-trilha-r3-cg.svg').default,
			agrupamento: 'TRILHA-R3 CIRURGIA 2024',
			produto_color: 'gradient-cyan2',
			titulo_formulario: ['Preencha o formulário para', <br />, 'realizar sua pré-inscrição!'],
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			campo_de_selecao: ['Fazer a prova esse ano', 'UFRJ', '01 01 01 00 1001 01', 'Outros'],
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar pré-inscrição',
			url_de_redirecionamento: '/pre-inscricao/trilha-r3-cg',
			titulo_popup_conclusao: '',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.',
		},
		{
			imagem: require('../../../../Assets/Arena/logo-arena-lp.svg').default,
			agrupamento: 'ARENA 2024.1',
			produto_color: 'revalida',
			titulo_formulario: ['Preencha o formulário para', <br />, 'realizar sua pré-inscrição!'],
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			// campo_de_selecao: ['Fazer a prova esse ano', 'UFRJ', '01 01 01 00 1001 01', 'Outros'],
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar pré-inscrição',
			url_de_redirecionamento: '/pre-inscricao/arena-revalida',
			titulo_popup_conclusao: '',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve enviaremos mais informações no seu e-mail cadastrado.',
		},
		{
			imagem: require('../../../../Pages/LandingPages/Subscription/Revalida/assets/logo-sprint-final-revalida.svg')
				.default,
			agrupamento: 'SPRINT FINAL REVALIDA 2024',
			produto_color: 'revalida',
			titulo_formulario: 'Inscreva-se na lista de espera',
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar inscrição',
			url_de_redirecionamento: '/pre-inscricao/sprint-final-revalida',
			titulo_popup_conclusao: '',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve enviaremos mais informações no seu e-mail cadastrado.',
		},
		{
			imagem: require('../Residencia/assets/logo-sprint-final-r3-cm.svg').default,
			agrupamento: 'SPRINT FINAL R3 CLÍNICA 2024',
			produto_color: 'gradient-purple',
			titulo_formulario: ['Preencha o formulário para', <br />, 'realizar sua pré-inscrição!'],
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			campo_de_selecao: ['Fazer a prova esse ano', 'UFRJ', '01 01 01 00 1001 01', 'Outros'],
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar pré-inscrição',
			url_de_redirecionamento: '/pre-inscricao/sprint-final/r3-cm',
			titulo_popup_conclusao: '',
			mensagem_popup:
				'Sua inscrição está confirmada! Em breve você também receberá uma confirmação pelo e-mail cadastrado.',
		},
		{
			imagem: require('../Residencia/assets/logo-cg-white.svg').default,
			text: [
				'O primeiro e único intensivo específico para as provas de especialidades cirúrgicas da USP ou do ENARE.',
				<br />,
				<br />,
				'Live de abertura de incrições',
				<br />,
				'06AGO • TERÇA-FEIRA • 20:00H',
			],
			agrupamento: 'HARDWORQ R3 CIRURGIA GERAL 2024',
			produto_color: 'gradient-cg',
			titulo_formulario: ['Inscreva-se para participar', <br />, 'da live do lançamento!'],
			placeholder_input_field_nome: '',
			placeholder_input_field_email: '',
			placeholder_input_field_telefone: '',
			placeholder_input_field_cpf: '',
			texto_termo_de_aceite: '',
			titulo_botao_submit: 'Confirmar Inscrição',
			url_de_redirecionamento: '/pre-inscricao/hardworq/r3-cg-usp',
			titulo_popup_conclusao: '',
			mensagem_popup: 'Sua inscrição está confirmada! Te enviaremos um lembrete momentos antes da live.',
		},
	]);

	const [arrayIndex, setArrayIndex] = useState();

	useEffect(() => {
		if (window.location.pathname === '/pre-inscricao/trilha-residencia') {
			return;
		} else if (window.location.pathname === '/pre-inscricao/trilha-residencia-2-anos') {
			setArrayIndex(1);
		} else if (window.location.pathname === '/pre-inscricao/trilha-r3-cm') {
			setArrayIndex(2);
		} else if (window.location.pathname === '/pre-inscricao/trilha-r3-cg') {
			setArrayIndex(3);
		} else if (window.location.pathname === '/pre-inscricao/sprint-final-revalida') {
			setArrayIndex(5);
		} else if (window.location.pathname === '/pre-inscricao/sprint-final/r3-cm') {
			setArrayIndex(6);
		} else if (window.location.pathname === '/pre-inscricao/hardworq/r3-cg-usp') {
			setArrayIndex(7);
		} else {
			setArrayIndex(4);
		}
	}, []);

	const matchesMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const matchesTablet = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container
			maxWidth={false}
			disableGutters={true}
		>
			<Helmet>
				<title>Pré Inscrição - Hardwork Medicina</title>
				<meta
					name="description"
					content="Nada supera o Hardwork. Nós vamos contigo em todas as etapas do Revalida INEP."
				/>
			</Helmet>
			{matchesMobile ? null : matchesTablet ? <Header /> : null}
			<Container
				fixed
				maxWidth="xl"
				disableGutters={true}
			>
				{matchesMobile || matchesTablet ? null : <Header />}
				<Grid
					container
					alignContent="center"
					justifyContent={'center'}
					className={`${matchesMobile ? classes.sectionMobile : classes.section}`}
					style={matchesMobile ? { marginTop: 80 } : matchesTablet ? { marginTop: 60 } : null}
				>
					<Grid
						item
						xs={12}
						md={5}
						container
						justifyContent="center"
						alignContent="flex-end"
						style={{ paddingBottom: 50 }}
						rowGap={2}
					>
						<img
							src={jsonMock_page_data[0]?.[arrayIndex]?.imagem}
							alt=""
							style={{ width: matchesMobile ? '80%' : null }}
						/>
						<Typography style={{ color: '#fff', fontSize: '24px', textAlign: 'center' }}>
							{jsonMock_page_data[0]?.[arrayIndex]?.text}
						</Typography>
						<Form
							group={jsonMock_page_data[0]?.[arrayIndex]?.agrupamento}
							title={jsonMock_page_data[0]?.[arrayIndex]?.titulo_formulario}
							titleButton={jsonMock_page_data[0]?.[arrayIndex]?.titulo_botao_submit}
							type={jsonMock_page_data[0]?.[arrayIndex]?.produto_color}
							urlToRedirect={jsonMock_page_data[0]?.[arrayIndex]?.url_de_redirecionamento}
							confirmationMessage={jsonMock_page_data[0]?.[arrayIndex]?.mensagem_popup}
						/>
					</Grid>
				</Grid>
				{matchesTablet ? null : <Footer />}
			</Container>
			{matchesTablet ? <Footer /> : null}
		</Container>
	);
}

const useStyles = makeStyles((theme) => ({
	section: {
		maxWidth: '1410px',
		margin: '5% auto',
	},
	sectionMobile: {
		padding: '2vh 5% 0',
	},
	mainTitle: {
		fontSize: '3.95rem !important',
		lineHeight: 'unset !important',
		fontWeight: 'bold !important',
		alignSelf: 'center',
		[theme.breakpoints.down('lg')]: {
			fontSize: '2.25rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.7rem !important',
		},
	},
	text: {
		fontSize: '1rem !important',
		lineHeight: '1.25 !important',
		[theme.breakpoints.down('lg')]: {
			fontSize: '1rem !important',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '0.8rem !important',
			marginBottom: '1rem !important',
		},
	},
	textImage: {
		[theme.breakpoints.down('lg')]: {
			width: '60%',
		},
	},
}));
